import React, { lazy, Suspense } from "react";
import { Switch } from "react-router-dom";
import Route from "./Route";
import { LoadingPage } from "~/react-components";
const UrlRedirectSSH = lazy(() =>
  import("../modules/Url/pages/UrlRedirectSSH")
);
const UrlRedirect = lazy(() => import("../modules/Url/pages/UrlRedirect"));
const UrlRedirectTest = lazy(() =>
  import("../modules/Url/pages/UrlRedirectTest")
);
const EmailView = lazy(() => import("../modules/email/pages/EmailView"));
const Routes = () => (
  <Switch>
    <Suspense fallback={<LoadingPage />}>
      <Route
        path="/email/webview/:token"
        isPrivate={false}
        isLayout={false}
        exact
        component={EmailView}
      />
      <Route path="/" isPrivate={false} exact component={UrlRedirectSSH} />
      <Route path="/:code" isPrivate={false} exact component={UrlRedirect} />
      <Route
        path="/:code/test"
        isPrivate={false}
        exact
        component={UrlRedirectTest}
      />
    </Suspense>
  </Switch>
);

export default Routes;
