import React from "react";
import { SocketIOProvider } from "./socketIO";
import { SettingsProvider } from "./useSettings";


// module /notifications

const AppProvider = ({ children }) => (

    <SettingsProvider>
     
        <SocketIOProvider>
         
        {children}

        </SocketIOProvider>
      
    </SettingsProvider>

);

export default AppProvider;
//    <UnreadProvider>  </UnreadProvider>
