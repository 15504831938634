import React from "react";
import { ToastContainer } from "react-toastify";
import AppProvider from "../business/hooks";
import "./styles/index.scss";
import "react-toastify/dist/ReactToastify.min.css";
import Routes from "./routes";
require("dotenv").config();
function App() {
  return (
    <AppProvider>
      <Routes />
      <ToastContainer />
    </AppProvider>
  );
}

export default App;
