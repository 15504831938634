import React from "react";
import styles from "./index.module.scss";
import Logo from "../../../business/assets/stinky-socks-logo.png";

function LoaderBubble() {
  return (
    <div className={styles.loading}>
     <img src={Logo} alt='img'></img>
    </div>
  );
}

export default LoaderBubble;
 