import React from "react";
import { toast } from "react-toastify";
const NotificationMsg = ({ notification, description }) => {
  return (
    <div>
      <h6>{notification}</h6>
      <p>{description.substring(0, 90) + "..."}</p>
    </div>
  );
};
class Notification {
  async warn(text, options) {
    return toast.warn(text, options);
  }
  async success(text, options) {
    return toast.success(text, options);
  }
  async info(text, options) {
    return toast.info(text, options);
  }
  async dark(text, options) {
    return toast.dark(text, options);
  }
  async error(text, options) {
    return toast.error(text, options);
  }
  async notification(notification) {
    return toast(
      <NotificationMsg
        notification={notification.notification}
        description={notification.description}
      />,
      {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: "default",
        progressStyle: {
          background: "#082154",
        },
      }
    );
  }
}

export default new Notification();
