import React from "react";
import { Route as ReactDOMRoute } from "react-router-dom";
import Layout from "../layout/dashboard";

const Route = ({
  isPrivate = false,
  isLayout = true,
  component: Component,
  ...rest
}) => {
  return (
    <ReactDOMRoute
      {...rest}
      render={() => {
        if (isLayout) {
          return (
            <Layout>
              <Component />
            </Layout>
          );
        } else {
          return <Component />;
        }
      }}
    />
  );
};

export default Route;
