import React, { createContext, useContext } from "react";

const SettingsContext = createContext({});
const SettingsProvider = ({ children }) => {
  const settingsPrivate = false;
  return (
    <SettingsContext.Provider
      value={{
        settingsPrivate,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};
function useSettings() {
  const context = useContext(SettingsContext);
  if (!context) {
    throw new Error("useToast must be used within a ToastProvider");
  }

  return context;
}

export { SettingsProvider, useSettings };
