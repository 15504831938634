import React from "react";
import cn from "classnames";
import styles from "./index.module.scss";
import Logo from "../../assets/stinky-socks-logo.png";
import "../../styles/index.scss";
export const Layout = ({ children }) => {
  return (
    <div className={cn(styles.container)}>
      <div className={cn(styles.header)}>
        <div>
          <img alt="Logo" src={Logo}></img>
        </div>
      </div>
      <div className={cn("container")}>{children}</div>
      <footer className={cn(styles.footer)}>
        <div>© 2022 StinkySocks Hockey. All rights reserved.</div>
      </footer>
    </div>
  );
};

export default Layout;
