import React from "react";
import { Col } from "react-bootstrap";

function ResponsiveCol({ cols = { col: 12 }, className, children }) {
  return (
    <Col 
      col={cols.col}
      sm={cols.sm || cols.col}
      md={cols.md || cols.col}
      lg={cols.lg || cols.col}
      lx={cols.lx || cols.col}
      className={className}
    >
      {children}
    </Col>
  );
}
// className="form-group mb-3"
export default ResponsiveCol;
