import React from "react";
import styles from "./index.module.scss";
function Toggle({ handleChange, checked,  id, }) {
  return (
    <div className={styles.SwitchCheckbox}>
      <input type="checkbox" id={id} checked={checked} defaultValue={checked||false} onChange={handleChange} />
      <label htmlFor={id} >
        <div></div>
      </label>
    </div>
  );
}

export default Toggle;
